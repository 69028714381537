import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { getUser } from './../firebase/userFirebase';
import { signOutFB} from './../firebase/signoutFirebase';
import { auth } from './../firebase/firebase';

const ProfileDropdown = ({isOpen, setIsOpen, profileDivRef}) => {
  const [username, setUsername] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("Set your delivery address");
  const [isDeliveryOpen, setDeliveryOpen] = useState(false);
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          getUser()
            .then((userData) => {
              const firstName = userData.fullname.split(' ')[0]
              setUsername(firstName);
              if(userData.delivery.trim() != ""){
                setDeliveryAddress(userData.delivery);
              }
              
            })
            .catch((error) => {
              console.error(error);
            });
        }
        });

        return () => unsubscribe(); // Cleanup the listener when the component unmounts
     };

     fetchData();

      const handleClickOutside = (event) => {
        if (profileDivRef.current && !profileDivRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
    
      if (isOpen) {
        document.addEventListener("mousedown", handleClickOutside);
      }
    
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [isOpen]);
  
  const changeAddress = () => {
      let path = `/address`;
      navigate(path);
  }

  const signOut = () => { 
      signOutFB();
      let path = `/login`;
      navigate(path);
  }

  return (
    <div>
      <div className={isOpen ? "profileDropdown" : "hidden"}>
        <p className="dropDownTitle">Hi, {username}</p>
        <p className="dropDownDelivery">Delivery address</p>
        <p className="dropDownText">{deliveryAddress}</p>
        <button className="dropDownButton" onClick={changeAddress}>Address </button>
        {/* <button className="dropDownButton" onClick={changeAddress}>Payment </button> */}
        <button className="dropDownLogout" onClick={signOut}>Log out</button>
      </div>
    </div>
  );
}

export default ProfileDropdown;
